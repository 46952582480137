/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h3: "h3",
    a: "a",
    div: "div",
    p: "p"
  }, _provideComponents(), props.components), {Hero, Outgrow, LogoCloud, Columns, Column, Video, Cta} = _components;
  if (!Column) _missingMdxReference("Column", true);
  if (!Columns) _missingMdxReference("Columns", true);
  if (!Cta) _missingMdxReference("Cta", true);
  if (!Hero) _missingMdxReference("Hero", true);
  if (!LogoCloud) _missingMdxReference("LogoCloud", true);
  if (!Outgrow) _missingMdxReference("Outgrow", true);
  if (!Video) _missingMdxReference("Video", true);
  return React.createElement(React.Fragment, null, React.createElement(Hero, {
    background: "deepPurple",
    backgroundImage: "primary",
    color: "white01",
    headline: "ROI Calculator",
    text: "SaaS companies that use Prismatic spend less engineering time on integrations, grow revenue, and reduce churn."
  }), "\n", React.createElement(Outgrow, {
    id: "647f9d40814434295e996975",
    title: "Prismatic ROI Calculator"
  }), "\n", React.createElement(LogoCloud, {
    logos: "/images/logo-big-tin-can.svg,/images/logo-elastic.svg,/images/logo-sisu.svg,/images/logo-raven-industries.svg,/images/logo-sound-thinking.svg,/images/logo-ingenious.svg,/images/logo-hatch.svg,/images/logo-deepstream.svg",
    title: "Trusted by SaaS teams from startup to Fortune 100"
  }), "\n", React.createElement(Columns, null, React.createElement(Column, {
    md: 8
  }, React.createElement(Video, {
    image: "/images/customers/frank-felice-thumbnail.png",
    videoId: "821739458"
  })), React.createElement(Column, {
    md: 4
  }, React.createElement(_components.h3, {
    id: "a-prismatic-roi-story-sisu",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#a-prismatic-roi-story-sisu",
    "aria-label": "a prismatic roi story sisu permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "A Prismatic ROI Story: Sisu"), React.createElement(_components.p, null, "At Sisu Software, integrations took many months to build and weren't scaling well. Frank Felice tells how Sisu used Prismatic to deliver integrations 6x faster, resulting in 5x revenue per subscription and 3% less churn."))), "\n", React.createElement(Cta, {
    copy: "Connect with our sales team for a detailed ROI analysis.",
    label: "Contact Sales",
    spacing: "default",
    title: "Want a more comprehensive estimate of the ROI you could achieve with Prismatic?",
    to: "/contact/"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
